import request from '@/utils/request';

// POST /noticeBanner/delete
//   删除
//
// GET /noticeBanner/info/{id}
// 根据Id获得信息
//
// GET /noticeBanner/list
// 获得列表
//
// POST /noticeBanner/save
// 保存
//
// POST /noticeBanner/update
// 修改

export function noticeBannerList(data) {
  return request({
    url: '/noticeBanner/list',
    method: 'get',
    query: data,
  });
}

export function noticeBannerSave(data) {
  return request({
    url: '/noticeBanner/save',
    method: 'post',
    data,
  });
}

export function noticeBannerDel(data) {
  return request({
    url: '/noticeBanner/delete',
    method: 'post',
    data,
  });
}

export function noticeBannerUpdate(data) {
  return request({
    url: '/noticeBanner/update',
    method: 'post',
    data,
  });
}

export function noticeBannerInfo(id) {
  return request({
    url: `/noticeBanner/info/${id}`,
    method: 'get',
  });
}
