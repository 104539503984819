<template>
  <div class="app-container online-info-page">
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column label="标题" align="center" prop="title"/>
      <el-table-column label="图片" align="center" prop="photo">
        <template slot-scope="{row}">
          <img class="photo-info" :src="row.photoUrl" @click="link(row.photoUrl)">
        </template>
      </el-table-column>
      <el-table-column label="链接" align="center" prop="link"/>
      <el-table-column label="开始时间" align="center" prop="startTime"/>
      <el-table-column label="结束时间" align="center" prop="endTime"/>
      <el-table-column label="排序" align="center" prop="sortBy"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />

    <!-- 添加或修改会员对话框 -->
    <!-- 添加或修改招聘职位对话框 -->
    <el-dialog :title="title" width="1050px" :visible.sync="open">
      <el-form ref="form" :model="form" size="small" :rules="rules" label-width="90px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="标题"/>
        </el-form-item>


        <el-form-item label="图片" required>
          <el-upload
            class="avatar-uploader"
            action="/"
            :show-file-list="false"
            :on-change="fileUpload"
            :on-success="handleAvatarSuccess"
            :auto-upload="false"
            :before-upload="beforeAvatarUpload">
            <img v-if="form.photoUrl" :src="form.photoUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"/>
          </el-upload>
        </el-form-item>

        <el-form-item label="跳转链接" prop="link">
          <el-input v-model="form.link" placeholder="默认以http：//开头"/>
        </el-form-item>

        <!--        <el-form-item label="链接" prop="link">-->
        <!--          <el-input v-model="form.link" placeholder="链接"/>-->
        <!--        </el-form-item>-->

        <el-form-item label="排序" prop="sortBy">
          <el-input type="number" v-model="form.sortBy" placeholder="排序"/>
        </el-form-item>

        <el-form-item label="时间范围" prop="dateRange">
          <el-date-picker
            value-format="yyyy-MM-DD HH:mm:ss"
            v-model="form.dateRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  noticeBannerDel, noticeBannerList, noticeBannerSave, noticeBannerUpdate, noticeBannerInfo,
} from '@/api/online';
import _ from 'lodash';

import { uploadFile, } from '@/api/tool/file';

export default {
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 100,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      // 表单参数
      form: {
        content: '',
        photo: '',
        photoUrl: '',
        dateRange: [],
        link: '',
        sortBy: '',
        title: '',
        startTime: '',
        endTime: '',
      },
      // 表单校验
      rules: {
        dateRange: [
          {
            required: true,
            message: '开始结束时间不能为空',
            trigger: 'blur'
          },
        ],
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onEditorReady(editor) { // 准备编辑器

    },
    onEditorBlur() {
    }, // 失去焦点事件
    onEditorFocus() {
    }, // 获得焦点事件
    onEditorChange() {
    }, // 内容改变事件

    fileUpload(file, fileList) {
      console.log(file, fileList);
      const data = new FormData();
      data.append('file', file.raw);
      uploadFile(data)
        .then(({ data: res }) => {
          this.form.photo = res.path;
          this.form.photoUrl = res.url;
        });
    },
    handleAvatarSuccess(res, file) {
      this.photo = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      noticeBannerList(queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        content: '',
        photo: '',
        photoUrl: '',
        dateRange: '',
        link: '',
        sortBy: '',
        title: '',
        startTime: '',
        endTime: '',
      };

      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增信息';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      noticeBannerInfo(id)
        .then((res) => {
          this.form = {
            ...res.data,
            dateRange:[
              res.data.createTime,
              res.data.endTime,
            ],
            // photo: res.data.photoUrl,
            // photoUrl: res.data.photo,
          };
          this.open = true;
          this.title = '修改信息';
        });
    },
    enableItem(row) {
      noticeBannerUpdate({
        id: row.id,
        positionStatus: row.positionStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            // photo: this.form.photoUrl,
            startTime: this.form.dateRange[0],
            endTime: this.form.dateRange[1],
          };

          if (params.id !== undefined) {
            noticeBannerUpdate(params)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              });
          } else {
            noticeBannerSave(params)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = [row.id];
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => noticeBannerDel(ids))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>

<style lang="less" scoped>
.photo-info {
  width: 100px;
  height: 60px;
  object-fit: cover;
}

.online-info-page {
  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
