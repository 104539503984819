import request from '@/utils/request'

// 查询文件信息列表
export function listFile(query) {
  return request({
    url: '/tool/file/list',
    method: 'get',
    params: query
  })
}

// 查询文件信息详细
export function getFile(id) {
  return request({
    url: `/tool/file/${id}`,
    method: 'get'
  })
}

// 新增文件信息
export function addFile(data) {
  return request({
    url: '/tool/file',
    method: 'post',
    data
  })
}

// 修改文件信息
export function updateFile(data) {
  return request({
    url: '/tool/file',
    method: 'put',
    data
  })
}

// 删除文件信息
export function delFile(id) {
  return request({
    url: `/tool/file/${id}`,
    method: 'delete'
  })
}

// 导出文件信息
export function exportFile(query) {
  return request({
    url: '/tool/file/export',
    method: 'get',
    params: query
  })
}

export function uploadFile(data) {
  return request({
    url: '/sys/oss/upload',
    method: 'post',
    data
  })
}
